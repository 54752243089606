<template>
  <x-dialog
    title="Как хотите оплатить?"
    :loading="isLoading"
  >
    <template
      v-if="message"
      #description
    >
      {{ message }}
    </template>

    <x-checkbox
      v-if="balanceInfo.available_balance"
      v-model="useBalance"
    >
      {{ `Списать с баланса ${balance} руб` }}
    </x-checkbox>

    <template #cancel>
      <x-btn
        color="primary"
        metrics="mmp_tariffs_xway_base_card"
        @click="initCardPayment"
      >
        Картой
      </x-btn>
    </template>

    <template #submit>
      <x-btn
        color="primary"
        metrics="mmp_tariffs_xway_base_check"
        @click="openPaymentAccountModal"
      >
        Выставить счет
      </x-btn>
    </template>

    <tariff-payment-account-modal @success="openInvoiceDoneModal" />
  </x-dialog>
</template>

<script>
import { initCardPayment } from '@/api/default/modules/payment'
import TariffInvoiceDoneModal from '@/components/Tariffs/TariffInvoiceDoneModal'
import TariffPaymentAccountModal from '@/components/Tariffs/TariffPaymentAccountModal'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('settings/finances')

export default {
  name: 'TariffPayModal',
  components: { TariffPaymentAccountModal },
  props: {
    amount: {
      type: Number,
      required: true
    },
    tariff: {
      type: Object,
      required: true
    },

    message: String
  },

  data () {
    return {
      useBalance: false,
      isLoading: false
    }
  },

  watch: {
    useBalance (val) {
      if (val) this.$metrics.hit('mmp_tariffs_xway_base_writeoff')
    }
  },

  computed: {
    ...mapState(['balanceInfo']),

    balance () {
      return this.balanceInfo.available_balance
    },

    total () {
      const balance = this.balanceInfo.available_balance
      return this.amount - balance
    },

    computedAmount () {
      return this.useBalance ? this.amount : (this.amount + this.balance)
    }
  },

  created () {
    this.fetchUserBalance()
  },

  methods: {
    ...mapActions(['getUserBalance']),

    async fetchUserBalance () {
      if (Object.keys(this.balanceInfo).length) return
      try {
        this.isLoading = true
        await this.getUserBalance()
      } finally {
        this.isLoading = false
      }
    },

    async initCardPayment () {
      try {
        const { data } = await initCardPayment({
          amount: this.computedAmount,
          tariff_id: this.tariff.id,
          is_recurrent: this.tariff.is_recurrent,
          term: this.tariff.term
        })

        if (data.data) {
          window.open(data.data.payment_url)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    },

    openPaymentAccountModal () {
      this.$modal.show('tariff-payment-account-modal',
        {
          amount: this.computedAmount,
          tariffId: this.tariff.id,
          tariffTerm: this.tariff.term,
          disableAmount: true
        })
    },

    openInvoiceDoneModal () {
      this.$metrics.hit('mmp_tariffs_xway_base_check_done')
      this.$dialog.show(TariffInvoiceDoneModal)
    }
  }
}
</script>
