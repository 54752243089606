<template>
  <x-dialog
    size="s"
    no-actions
  >
    <div class="tariffs-success-modal">
      <div class="tariffs-success-modal__icon">
        <svg-icon name="success--with-border-gap" />
      </div>
      <heading
        level="3"
        class="tariffs-success-modal__title"
      >
        Оплата прошла успешно!
      </heading>
      <x-btn
        class="tariffs-success-modal__button"
        @click="close"
      >
        Замечательно
      </x-btn>
    </div>
  </x-dialog>
</template>
<script>
import Heading from '@/components/Common/Heading'
import ROUTES from '@/router/ROUTES'

export default {
  name: 'TariffSuccessModal',
  components: { Heading },
  methods: {
    close () {
      this.$dialog.hide()
      this.$router.push(ROUTES.TARIFFS.path)
    }
  }
}
</script>

<style lang="stylus">
.tariffs-success-modal
  display flex
  flex-direction column
  align-items center

  &__icon
    width 72px
    height 72px
    margin-bottom 20px

    & svg
      max-width 100%
      max-height 100%

  &__button
    margin-top 20px
    width 100%
</style>
