<template>
  <x-dialog
    size="s"
    no-actions
  >
    <div class="tariffs-error-modal">
      <div class="tariffs-error-modal__icon">
        <svg-icon name="error--gray" />
      </div>
      <heading
        level="3"
        class="tariffs-error-modal__title"
      >
        Проблема с оплатой
      </heading>
      <div class="tariffs-error-modal__description">
        Попробуйте снова или свяжитесь со службой поддержки по почте
        <br>
        <x-link
          href="mailto:seller@xway.ru"
          primary
        >
          seller@xway.ru
        </x-link>
      </div>
      <x-btn
        class="tariffs-error-modal__button"
        color="gray"
        @click="close"
      >
        Закрыть
      </x-btn>
    </div>
  </x-dialog>
</template>
<script>
import Heading from '@/components/Common/Heading'
import ROUTES from '@/router/ROUTES'

export default {
  name: 'TariffErrorModal',
  components: { Heading },
  methods: {
    close () {
      this.$dialog.hide()
      this.$router.push(ROUTES.TARIFFS.path)
    }
  }
}
</script>

<style lang="stylus">
.tariffs-error-modal
  display flex
  flex-direction column
  align-items center

  &__icon
    width 72px
    height 72px
    margin-bottom 20px

    & svg
      max-width 100%
      max-height 100%

  &__button
    margin-top 20px
    width 100%

  &__description
    font-size 14px
    text-align center
</style>
