<template>
  <x-page
    class="tariffs"
    :breadcrumbs="breadcrumbs"
    :title="pageTitle"
  >
    <div class="d-flex mb-10">
      <!-- todo рассмотреть возможность сделать один виджет с онбордингом -->
      <x-loader v-if="isLoading" />
      <tariff-details-card
        v-else
        :change-action="scrollToPayTariffs"
      />
    </div>
    <h3
      ref="tariffsTable"
      class="headline-3 mb-6"
    >
      {{ companyDetails.name }} Тарифы
    </h3>
    <x-loader v-if="isLoading" />
    <x-card
      v-else
      class="tariffs-table mb-10"
    >
      <h4 class="headline-4 mb-6">
        Состав тарифов
      </h4>
      <x-tabs
        v-model="selectedTabId"
        :tabs="tabs"
        inner
      />
      <div class="d-flex">
        <div class="name-cell">
          {{ headerSection.name }}
        </div>
        <div
          v-for="(tariff, index) in tariffsListFiltered"
          :key="index"
          class="common-cell"
          :class="getCellClass(index)"
        >
          <span>
            {{ tariff.name }}
          </span>
        </div>
      </div>
      <div>
        <x-collapse
          v-for="section in tariffsSections"
          :key="section.id"
          theme="content-white"
          class="mb-2"
          :storage-id="section.id"
          :title="section.name"
        >
          <div
            v-for="(option, i) in section.options"
            :key="i"
          >
            <x-divider />
            <div class="d-flex">
              <div
                class="name-cell"
              >
                {{ option.name }}
              </div>
              <div
                v-for="(cell, index) in tariffsListFiltered.length"
                :key="cell"
                class="common-cell"
                :class="getCellClass(index)"
              >
                <x-icon
                  v-if="hasSuchOption(option.key, tariffsListFiltered[index])"
                  color="green"
                  name="check-circle"
                />
              </div>
            </div>
          </div>
        </x-collapse>
        <div class="d-flex">
          <div class="name-cell">
            Ограничения по магазинам
          </div>
          <div
            v-for="(tariff, index) in tariffsListFiltered"
            :key="index"
            class="common-cell"
            :class="getCellClass(index)"
          >
            <span>
              {{ tariff.shops_limit || 'Без ограничений' }}
            </span>
          </div>
        </div>
        <div class="pick-tariffs-panel">
          <div class="name-cell" />
          <div
            v-for="(tariff, index) in tariffsListFiltered"
            :key="index"
            class="common-cell"
            :class="getCellClass(index)"
          >
            <div class="d-flex flex-column">
              <span class="my-4">
                <span>
                  {{ getTariffPrice(tariff) }}
                </span>
              </span>
              <x-btn
                :outline="!isBaseTariff(index)"
                class="change-tariff-btn mb-3"
                :class="{ 'change-tariff-btn--hidden' : !isBtnHidden(tariff) }"
                @click="onSelectTariff(tariff)"
              >
                Подключить
              </x-btn>
            </div>
          </div>
        </div>
      </div>
    </x-card>
    <h3 class="headline-3 mb-6">
      Услуги {{ companyDetails.name }}
    </h3>
    <service-list />
    <success-modal>
      <template #title>
        Спасибо!
      </template>
      <template>
        <p class="text-l-left text">
          Ваша заявка отправлена. В ближайшее время с Вами свяжется наш специалист.
        </p>
      </template>
    </success-modal>
  </x-page>
</template>

<script>
import TariffSuccessModal from '@/components/Tariffs/TariffSuccessModal'
import { TARIFF } from '@/constants/tariffs'
import { createNamespacedHelpers } from 'vuex'
import TariffErrorModal from '@/components/Tariffs/TariffErrorModal'
import TariffPayModal from '@/components/Tariffs/TariffPayModal'
import companyDetails from '@/plugins/companyDetails'
import SubmitModal from '@/views/Tariffs/SubmitModal'
import SupportModal from '@/components/NotPermit/SupportModal'
import headerSection from '@/views/Tariffs/headerSection'
import tariffsSections from '@/views/Tariffs/tariffsSections'
import { ONE_MONTH_IN_DAYS } from '@/constants/timings'
import ServiceList from '@/components/Services/ServiceList'
import TariffDetailsCard from '@/views/Tariffs/TariffDetailsCard'
import SuccessModal from '@/components/Modals/SuccessModal'

const { mapActions, mapState } = createNamespacedHelpers('settings/finances')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')
const { mapActions: mapProfileActions, mapGetters: profileGetters } = createNamespacedHelpers('profile')

export default {
  components: {
    SuccessModal,
    TariffDetailsCard,
    ServiceList
  },

  props: {
    unpaid: Boolean,
    modal: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      isLoading: false,
      companyDetails,

      breadcrumbs: [{ text: 'Тарифы', to: { name: 'tariffs' } }],
      selectedTabId: 0,
      tabs: [
        {
          title: '1 месяц',
          term: ONE_MONTH_IN_DAYS
          // metrics: ''
        },
        {
          title: '3 месяца',
          term: ONE_MONTH_IN_DAYS * 3
        },
        {
          title: '6 месяцев',
          term: ONE_MONTH_IN_DAYS * 6
        },
        {
          title: '12 месяцев',
          term: ONE_MONTH_IN_DAYS * 12
        }
      ],
      headerSection,
      tariffsSections
    }
  },

  computed: {
    ...mapState([
      'tariffsList',
      'tariffPriceList'
    ]),

    ...profileGetters(['isCustomTariffConfigured']),

    pageTitle () {
      return `Тарифы кабинета продавца ${companyDetails.name} MMP`
    },

    tariffsListFiltered () {
      return this.tariffsList.filter(el => el.display_on_frontend)
    },

    currentTab () {
      return this.tabs[this.selectedTabId]
    },

    allTerms () {
      return this.tabs.map(el => el.term)
    },

    customTariffId () {
      return this.tariffsList.find(el => el.key === TARIFF.CUSTOM.key)?.id
    },

    customTariffTerm () {
      const [customTerm] = Object.keys(this.tariffPriceList[this.customTariffId])

      return +customTerm
    },

    isCustomTariffAvailable () {
      return this.tariffPriceList[this.customTariffId]?.[this.customTariffTerm]?.is_available
    }
  },

  created () {
    this.fetchData()
    if (this.unpaid) {
      this.$toast.info('Доступно только в платном тарифе')
    } else if (this.modal) {
      if (this.modal === 'success') {
        this.$dialog.show(TariffSuccessModal)
      } else if (this.modal === 'error') {
        this.$dialog.show(TariffErrorModal)
      }
    }
  },

  methods: {
    ...mapActions([
      'getTariffsList',
      'changeTariff',
      'calculateTariffPrice'
    ]),
    ...mapTrackingActions(['setEvent']),
    ...mapProfileActions(['getProfile']),

    getTariffPricePerPeriod (tariff) {
      return (+this.tariffPriceList[tariff.id]?.[this.currentTab.term]?.tariff_price) || 0
    },

    getCellClass (index) {
      return `tariff--${this.tariffsListFiltered[index]?.key}`
    },

    isBaseTariff (index) {
      return this.tariffsListFiltered[index]?.key === TARIFF.BASE2.key
    },

    isBtnHidden (tariff) {
      return tariff.key !== TARIFF.FREE.key && tariff.key !== TARIFF.TEST.key && tariff.key !== TARIFF.TEMPORARY.key
    },

    getTariffPrice (tariff) {
      if (tariff.key === TARIFF.CUSTOM.key) return this.getCustomTariffPriceText()

      return this.getTariffPricePerPeriod(tariff).toLocaleString('ru') + ' ₽'
    },

    getCustomTariffPriceText () {
      if (this.isCustomTariffConfigured) {
        return (+this.tariffPriceList[this.customTariffId]?.[this.customTariffTerm]?.tariff_price).toLocaleString('ru') + ' ₽'
      }

      return 'По запросу'
    },

    getCustomTariffPriceByKey (key) {
      return +this.tariffPriceList[this.customTariffId]?.[this.customTariffTerm]?.[key]
    },

    hasSuchOption (optionKey, tariff) {
      return tariff.permission_keys?.includes(optionKey)
    },

    async fetchData () {
      try {
        this.isLoading = true
        await this.getTariffsList(this.allTerms)
        await this.getProfile()
      } finally {
        this.isLoading = false
      }
    },

    isTariffAvailable (tariff) {
      return this.tariffPriceList[tariff.id]?.[this.currentTab.term]?.is_available
    },

    async onSelectTariff (tariff) {
      if (tariff.key === TARIFF.CUSTOM.key) {
        this.handleCustomTariff(tariff)
        return
      }
      if (!this.isTariffAvailable(tariff)) {
        this.$dialog.show(SupportModal)
        return
      }
      if (this.getTariffPricePerPeriod(tariff)) {
        const term = this.currentTab.term
        const newPrice = (await this.calculateTariffPrice(
          { tariffIds: [tariff.id], terms: [term] }))?.[tariff.id]?.[term]?.price
        this.$dialog.show(SubmitModal, () => ({
          onSuccess: () => this.setTariff(tariff, term),
          name: tariff.name,
          price: this.getTariffPricePerPeriod(tariff),
          newPrice,
          term
        }))
      }
    },

    async handleCustomTariff (tariff) {
      if (this.isCustomTariffConfigured) {
        if (this.isCustomTariffAvailable) {
          this.$dialog.show(SubmitModal, () => ({
            onSuccess: () => this.setTariff(tariff, this.customTariffTerm),
            name: tariff.name,
            price: this.getCustomTariffPriceByKey('tariff_price'),
            newPrice: this.getCustomTariffPriceByKey('change_price'),
            term: this.customTariffTerm
          }))
        } else {
          this.$dialog.show(SupportModal)
        }
      } else {
        try {
          await this.changeTariff({
            tariff_id: tariff.id,
            /* любое число */
            term: 777
          })
          this.$modal.show('success-modal')
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
    },

    scrollToPayTariffs () {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.tariffsTable?.scrollIntoView({ behavior: 'smooth' })
    },

    async setTariff (tariff, term) {
      this.setEvent({
        name: 'mmp_tariffs_xway_base',
        product: 'MMP',
        screen: 'mmp_tariffs'
      })
      try {
        await this.changeTariff({
          tariff_id: tariff.id,
          term
        })
        this.$toast.info('Тариф сменён')
        this.fetchData()
      } catch (err) {
        if (err.response.status === 422) {
          const amount = err.response.data?.errorMessage?.need_amount
          const message = err.response.data?.errorMessage?.message

          if (amount) {
            this.$dialog.show(TariffPayModal, () => ({ amount, tariff: { ...tariff, is_recurrent: true, term }, message }))
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  $name-cell-width = 30%

  .tariffs-table
    overflow-x auto

  .name-cell
    width $name-cell-width
    padding $space * 2 $space * 3
    flex-shrink 0

  .common-cell
    display flex
    align-items center
    justify-content center
    font-size 15px
    line-height 15px
    min-width 150px
    width "calc((100% - %s)/5)" % $name-cell-width
    text-align center
    padding $space * 2
    color $colors.gray-darker
    min-height 38px

  .tariff
    &--base2
      background-color $colors.gray-lighter
      color $colors.gray-darkest-2
      font-weight 600

  .pick-tariffs-panel
    display flex

  .change-tariff-btn
    &--hidden
      visibility hidden
      pointer-events none
</style>
