<template>
  <x-dialog
    no-actions
    siz="m"
  >
    <div class="d-flex flex-column align-center">
      <x-icon
        color="warning"
        size="xl"
        name="alert-triangle"
      />
      <h3 class="headline-3 mb-6">
        Смена тарифа невозможна
      </h3>
      <h6 class="headline-6">
        обратитесь в техническую поддержку
      </h6>
      <div>
        <x-link :href="`mailto:${SUPPORT.email}`">
          {{ SUPPORT.email }}
        </x-link>
      </div>
    </div>
  </x-dialog>
</template>

<script>
import { SUPPORT } from '@/constants/contacts'

export default {
  name: 'SupportModal',
  data: () => ({
    SUPPORT
  })
}
</script>

<style scoped>

</style>
