import PERMISSION_KEY from '@/constants/PERMISSION_KEY'

export default [
  {
    id: 'product-analytics',
    name: 'Товарная аналитика',
    options: [
      {
        name: 'Аналитический дашборд по всем магазинам',
        key: PERMISSION_KEY.SELLER_DASHBOARD
      },
      {
        name: 'Расширенный аналитический дашборд по всем магазинам',
        key: PERMISSION_KEY.SELLER_DASHBOARD_EXTENDED
      },
      {
        name: 'Анализ показателей товаров',
        key: PERMISSION_KEY.ANALYTICS_INDICATORS
      },
      {
        name: 'Анализ поисковой выдачи',
        key: PERMISSION_KEY.ANALYTICS_POSITIONS
      },
      {
        name: 'ABC анализ',
        key: PERMISSION_KEY.ANALYTICS_ABC_REPORT
      },
      {
        name: 'Анализ контента',
        key: PERMISSION_KEY.ANALYTICS_CONTENT
      },
      {
        name: 'Мониторинг цен',
        key: PERMISSION_KEY.ANALYTICS_PRICES
      },
      {
        name: 'Сравнение с конкурентами',
        key: PERMISSION_KEY.ANALYTICS_COMPETITORS_COMPARE
      },
      {
        name: 'Прогноз поставок',
        key: PERMISSION_KEY.SELLER_REPORT_TURNOVER
      }
    ]
  },
  {
    id: 'wildberries-analytics',
    name: 'Анализ рынка Wildberries',
    options: [
      {
        name: 'Анализ товаров',
        key: PERMISSION_KEY.ORM_ANALYSE_PRODUCTS
      },
      {
        name: 'Анализ продавцов',
        key: PERMISSION_KEY.ORM_ANALYSE_SELLERS
      },
      {
        name: 'Анализ брендов',
        key: PERMISSION_KEY.ORM_ANALYSE_BRANDS
      },
      {
        name: 'Анализ категорий',
        key: PERMISSION_KEY.ORM_ANALYSE_CATEGORIES
      }
    ]
  },
  {
    id: 'market-control',
    name: 'Управление магазинами',
    options: [
      // /orders
      {
        name: 'Лента заказов',
        key: PERMISSION_KEY.SELLER_ORDER_LIST
      },
      {
        name: 'Товары',
        key: PERMISSION_KEY.SELLER_PRODUCT_LIST
      },
      {
        name: 'Управление ценами и остатками на AliExpress',
        key: PERMISSION_KEY.SELLER_PRICES_STOCKS
      },
      {
        name: 'Регистрация в акциях на AliExpress',
        key: PERMISSION_KEY.SELLER_PROMO
      }
    ]
  }
]
