<template>
  <x-dialog
    class="text"
    size="m"
    :submit-text="submitText"
    :submit-disabled="isSubmitDisabled"
    @submit="onSubmit"
  >
    <div class="mb-6 text-center">
      <x-icon
        style="width: 60px; height: 60px"
        class="gray-dark--text"
        name="check-square"
      />
    </div>
    <h2 class="headline-3 text-center mb-5">
      Вы оформляете подписку на тариф «{{ name }}» стоимостью
      <span :class="{'old-price': price !== newPrice}">{{ price.toLocaleString('ru') }}</span>
      <span
        v-if="price !== newPrice"
        class="new-price"
      >
        {{ newPrice.toLocaleString('ru') }}
      </span>
      {{ formatPrice }} на {{ periodInMonths }}
      <span class="d-inline-flex">
        <x-icon-tooltip
          v-if="price !== newPrice"
          style="transform: translateY(2px)"
          class="ml-1"
        >
          Цена с учетом перевода стоимости остатка по текущему тарифу и средств на Вашем балансе
        </x-icon-tooltip>
      </span>
    </h2>
    <div>
      <div class="mb-2 d-flex">
        <x-checkbox
          v-model="first"
          class="mr-2"
        />
        <div>
          <span class="mr-1">
            Я согласен с
          </span>
          <x-link :href="OFFER">
            условиями оферты
          </x-link>
          <span class="mx-1">
            и
          </span>
          <x-link :href="REMUNERATION_AGREEMENT">
            соглашением о рекуррентных платежах
          </x-link>
        </div>
      </div>

      <div class="mb-2 d-flex">
        <x-checkbox
          v-model="second"
          class="mr-2"
        />
        <div>
          <span class="mr-1">
            Я согласен с
          </span>
          <x-link :href="SUBSCRIPTION_CONSENT">
            сохранением учетных данных для будущих транзакций
          </x-link>
        </div>
      </div>

      <div class="mb-2 d-flex">
        <x-checkbox
          v-model="third"
          class="mr-2"
        />
        <div>
          <span class="mr-1">
            Я согласен на
          </span>
          <x-link :href="STORAGE_CREDENTIALS_AGREEMENT">
            соглашением с владельцем карты
          </x-link>
          <span class="mx-1">
            и
          </span>
          <x-link :href="PRIVACY">
            политикой обработки персональных данных
          </x-link>
        </div>
      </div>
    </div>
  </x-dialog>
</template>

<script>
import { OFFER, PRIVACY, SUBSCRIPTION_CONSENT, STORAGE_CREDENTIALS_AGREEMENT, REMUNERATION_AGREEMENT } from '@/constants/links'
import createCountFormatter from '@/utils/count-formatter'

export default {
  name: 'SubmitModal',

  props: {
    onSuccess: {
      type: Function,
      required: true
    },
    name: String,
    price: Number,
    newPrice: Number,
    term: Number
  },

  data: () => ({
    OFFER,
    PRIVACY,
    SUBSCRIPTION_CONSENT,
    STORAGE_CREDENTIALS_AGREEMENT,
    REMUNERATION_AGREEMENT,
    submitText: 'Сменить тариф',
    first: false,
    second: false,
    third: false
  }),

  computed: {
    isSubmitDisabled () {
      return !(this.first && this.second && this.third)
    },

    periodInMonths () {
      const format = createCountFormatter({ few: 'дней', one: 'день', two: 'дня' })

      return `${this.term} ${format(this.term)}`
    },

    formatPrice () {
      const price = this.price === this.newPrice ? this.price : this.newPrice

      const priceFormat = createCountFormatter({ few: 'рублей', one: 'рубль', two: 'рубля' })

      return priceFormat(price)
    }
  },

  methods: {
    onSubmit () {
      this.$dialog.hide()
      this.onSuccess()
    }
  }
}
</script>

<style lang="stylus" scoped>
.old-price
  text-decoration line-through
  color $colors.gray
</style>
