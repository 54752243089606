export default [
  {
    name: 'Бесплатный',
    id: 1,
    price: '0 ₽'
  },
  {
    name: 'Тестовый',
    id: 2,
    price: '0 ₽'
  },
  {
    name: 'Базовый',
    id: 3,
    price: '2 900 ₽'
  },
  {
    name: 'Продвинутый',
    id: 4,
    price: '7 900 ₽'
  },
  {
    name: 'Индивидуальный',
    id: 5,
    price: 'Индивидуальный\nрасчет'
  }
]
